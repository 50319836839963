export const countryList = [
  {
    countryName: "Afghanistan",
    flag: "https://upload.wikimedia.org/wikipedia/commons/9/9a/Flag_of_Afghanistan.svg",
    iso2: "AF",
    iso3: "AFG",
  },
  {
    countryName: "Albania",
    flag: "https://upload.wikimedia.org/wikipedia/commons/3/36/Flag_of_Albania.svg",
    iso2: "AL",
    iso3: "ALB",
  },
  {
    countryName: "Algeria",
    flag: "https://upload.wikimedia.org/wikipedia/commons/7/77/Flag_of_Algeria.svg",
    iso2: "DZ",
    iso3: "DZA",
  },
  {
    countryName: "Andorra",
    flag: "https://upload.wikimedia.org/wikipedia/commons/1/19/Flag_of_Andorra.svg",
    iso2: "AD",
    iso3: "AND",
  },
  {
    countryName: "Angola",
    flag: "https://upload.wikimedia.org/wikipedia/commons/9/9d/Flag_of_Angola.svg",
    iso2: "AO",
    iso3: "AGO",
  },
  {
    countryName: "Anguilla",
    flag: "https://upload.wikimedia.org/wikipedia/commons/b/b4/Flag_of_Anguilla.svg",
    iso2: "AI",
    iso3: "AIA",
  },
  {
    countryName: "Antigua and Barbuda",
    flag: "https://upload.wikimedia.org/wikipedia/commons/8/89/Flag_of_Antigua_and_Barbuda.svg",
    iso2: "AG",
    iso3: "ATG",
  },
  {
    countryName: "Argentina",
    flag: "https://upload.wikimedia.org/wikipedia/commons/1/1a/Flag_of_Argentina.svg",
    iso2: "AR",
    iso3: "ARG",
  },
  {
    countryName: "Armenia",
    flag: "https://upload.wikimedia.org/wikipedia/commons/2/2f/Flag_of_Armenia.svg",
    iso2: "AM",
    iso3: "ARM",
  },
  {
    countryName: "Aruba",
    flag: "https://upload.wikimedia.org/wikipedia/commons/f/f6/Flag_of_Aruba.svg",
    iso2: "AW",
    iso3: "ABW",
  },
  {
    countryName: "Australia",
    flag: "https://upload.wikimedia.org/wikipedia/commons/8/88/Flag_of_Australia_%28converted%29.svg",
    iso2: "AU",
    iso3: "AUS",
  },
  {
    countryName: "Austria",
    flag: "https://upload.wikimedia.org/wikipedia/commons/4/41/Flag_of_Austria.svg",
    iso2: "AT",
    iso3: "AUT",
  },
  {
    countryName: "Azerbaijan",
    flag: "https://upload.wikimedia.org/wikipedia/commons/d/dd/Flag_of_Azerbaijan.svg",
    iso2: "AZ",
    iso3: "AZE",
  },
  {
    countryName: "Bahamas",
    flag: "https://upload.wikimedia.org/wikipedia/commons/9/93/Flag_of_the_Bahamas.svg",
    iso2: "BS",
    iso3: "BHS",
  },
  {
    countryName: "Bahrain",
    flag: "https://upload.wikimedia.org/wikipedia/commons/2/2c/Flag_of_Bahrain.svg",
    iso2: "BH",
    iso3: "BHR",
  },
  {
    countryName: "Bangladesh",
    flag: "https://upload.wikimedia.org/wikipedia/commons/f/f9/Flag_of_Bangladesh.svg",
    iso2: "BD",
    iso3: "BGD",
  },
  {
    countryName: "Barbados",
    flag: "https://upload.wikimedia.org/wikipedia/commons/e/ef/Flag_of_Barbados.svg",
    iso2: "BB",
    iso3: "BRB",
  },
  {
    countryName: "Belarus",
    flag: "https://upload.wikimedia.org/wikipedia/commons/8/85/Flag_of_Belarus.svg",
    iso2: "BY",
    iso3: "BLR",
  },
  {
    countryName: "Belgium",
    flag: "https://upload.wikimedia.org/wikipedia/commons/6/65/Flag_of_Belgium.svg",
    iso2: "BE",
    iso3: "BEL",
  },
  {
    countryName: "Belize",
    flag: "https://upload.wikimedia.org/wikipedia/commons/e/e7/Flag_of_Belize.svg",
    iso2: "BZ",
    iso3: "BLZ",
  },
  {
    countryName: "Benin",
    flag: "https://upload.wikimedia.org/wikipedia/commons/0/0a/Flag_of_Benin.svg",
    iso2: "BJ",
    iso3: "BEN",
  },
  {
    countryName: "Bermuda",
    flag: "https://upload.wikimedia.org/wikipedia/commons/b/bf/Flag_of_Bermuda.svg",
    iso2: "BM",
    iso3: "BMU",
  },
  {
    countryName: "Bhutan",
    flag: "https://upload.wikimedia.org/wikipedia/commons/9/91/Flag_of_Bhutan.svg",
    iso2: "BT",
    iso3: "BTN",
  },
  {
    countryName: "Bosnia and Herzegovina",
    flag: "https://upload.wikimedia.org/wikipedia/commons/b/bf/Flag_of_Bosnia_and_Herzegovina.svg",
    iso2: "BA",
    iso3: "BIH",
  },
  {
    countryName: "Botswana",
    flag: "https://upload.wikimedia.org/wikipedia/commons/f/fa/Flag_of_Botswana.svg",
    iso2: "BW",
    iso3: "BWA",
  },
  {
    countryName: "Bouvet Island",
    flag: "https://upload.wikimedia.org/wikipedia/commons/d/d9/Flag_of_Norway.svg",
    iso2: "BV",
    iso3: "BVT",
  },
  {
    countryName: "Brazil",
    flag: "https://upload.wikimedia.org/wikipedia/en/0/05/Flag_of_Brazil.svg",
    iso2: "BR",
    iso3: "BRA",
  },
  {
    countryName: "British Indian Ocean Territory",
    flag: "https://upload.wikimedia.org/wikipedia/commons/6/65/Flag_of_the_Commissioner_of_the_British_Indian_Ocean_Territory.svg",
    iso2: "IO",
    iso3: "IOT",
  },
  {
    countryName: "Brunei",
    flag: "https://upload.wikimedia.org/wikipedia/commons/9/9c/Flag_of_Brunei.svg",
    iso2: "BN",
    iso3: "BRN",
  },
  {
    countryName: "Bulgaria",
    flag: "https://upload.wikimedia.org/wikipedia/commons/9/9a/Flag_of_Bulgaria.svg",
    iso2: "BG",
    iso3: "BGR",
  },
  {
    countryName: "Burkina Faso",
    flag: "https://upload.wikimedia.org/wikipedia/commons/3/31/Flag_of_Burkina_Faso.svg",
    iso2: "BF",
    iso3: "BFA",
  },
  {
    countryName: "Burundi",
    flag: "https://upload.wikimedia.org/wikipedia/commons/5/50/Flag_of_Burundi.svg",
    iso2: "BI",
    iso3: "BDI",
  },
  {
    countryName: "Cambodia",
    flag: "https://upload.wikimedia.org/wikipedia/commons/8/83/Flag_of_Cambodia.svg",
    iso2: "KH",
    iso3: "KHM",
  },
  {
    countryName: "Cameroon",
    flag: "https://upload.wikimedia.org/wikipedia/commons/4/4f/Flag_of_Cameroon.svg",
    iso2: "CM",
    iso3: "CMR",
  },
  {
    countryName: "Canada",
    flag: "https://upload.wikimedia.org/wikipedia/en/c/cf/Flag_of_Canada.svg",
    iso2: "CA",
    iso3: "CAN",
  },
  {
    countryName: "Cape Verde",
    flag: "https://upload.wikimedia.org/wikipedia/commons/3/38/Flag_of_Cape_Verde.svg",
    iso2: "CV",
    iso3: "CPV",
  },
  {
    countryName: "Cayman Islands",
    flag: "https://upload.wikimedia.org/wikipedia/commons/0/0f/Flag_of_the_Cayman_Islands.svg",
    iso2: "KY",
    iso3: "CYM",
  },
  {
    countryName: "Central African Republic",
    flag: "https://upload.wikimedia.org/wikipedia/commons/6/6f/Flag_of_the_Central_African_Republic.svg",
    iso2: "CF",
    iso3: "CAF",
  },
  {
    countryName: "Chad",
    flag: "https://upload.wikimedia.org/wikipedia/commons/4/4b/Flag_of_Chad.svg",
    iso2: "TD",
    iso3: "TCD",
  },
  {
    countryName: "Chile",
    flag: "https://upload.wikimedia.org/wikipedia/commons/7/78/Flag_of_Chile.svg",
    iso2: "CL",
    iso3: "CHL",
  },
  {
    countryName: "China",
    flag: "https://upload.wikimedia.org/wikipedia/commons/f/fa/Flag_of_the_People%27s_Republic_of_China.svg",
    iso2: "CN",
    iso3: "CHN",
  },
  {
    countryName: "Christmas Island",
    flag: "https://upload.wikimedia.org/wikipedia/commons/6/67/Flag_of_Christmas_Island.svg",
    iso2: "CX",
    iso3: "CXR",
  },
  {
    countryName: "Cocos (Keeling) Islands",
    flag: "https://upload.wikimedia.org/wikipedia/commons/7/74/Flag_of_the_Cocos_%28Keeling%29_Islands.svg",
    iso2: "CC",
    iso3: "CCK",
  },
  {
    countryName: "Colombia",
    flag: "https://upload.wikimedia.org/wikipedia/commons/2/21/Flag_of_Colombia.svg",
    iso2: "CO",
    iso3: "COL",
  },
  {
    countryName: "Comoros",
    flag: "https://upload.wikimedia.org/wikipedia/commons/9/94/Flag_of_the_Comoros.svg",
    iso2: "KM",
    iso3: "COM",
  },
  {
    countryName: "Congo",
    flag: "https://upload.wikimedia.org/wikipedia/commons/9/92/Flag_of_the_Republic_of_the_Congo.svg",
    iso2: "CG",
    iso3: "COG",
  },
  {
    countryName: "Cook Islands",
    flag: "https://upload.wikimedia.org/wikipedia/commons/3/35/Flag_of_the_Cook_Islands.svg",
    iso2: "CK",
    iso3: "COK",
  },
  {
    countryName: "Costa Rica",
    flag: "https://upload.wikimedia.org/wikipedia/commons/b/bc/Flag_of_Costa_Rica_%28state%29.svg",
    iso2: "CR",
    iso3: "CRI",
  },
  {
    countryName: "Croatia",
    flag: "https://upload.wikimedia.org/wikipedia/commons/1/1b/Flag_of_Croatia.svg",
    iso2: "HR",
    iso3: "HRV",
  },
  {
    countryName: "Cuba",
    flag: "https://upload.wikimedia.org/wikipedia/commons/b/bd/Flag_of_Cuba.svg",
    iso2: "CU",
    iso3: "CUB",
  },
  {
    countryName: "Cyprus",
    flag: "https://upload.wikimedia.org/wikipedia/commons/d/d4/Flag_of_Cyprus.svg",
    iso2: "CY",
    iso3: "CYP",
  },
  {
    countryName: "Czech Republic",
    flag: "https://upload.wikimedia.org/wikipedia/commons/c/cb/Flag_of_the_Czech_Republic.svg",
    iso2: "CZ",
    iso3: "CZE",
  },
  {
    countryName: "Denmark",
    flag: "https://upload.wikimedia.org/wikipedia/commons/9/9c/Flag_of_Denmark.svg",
    iso2: "DK",
    iso3: "DNK",
  },
  {
    countryName: "Djibouti",
    flag: "https://upload.wikimedia.org/wikipedia/commons/3/34/Flag_of_Djibouti.svg",
    iso2: "DJ",
    iso3: "DJI",
  },
  {
    countryName: "Dominica",
    flag: "https://upload.wikimedia.org/wikipedia/commons/c/c4/Flag_of_Dominica.svg",
    iso2: "DM",
    iso3: "DMA",
  },
  {
    countryName: "Dominican Republic",
    flag: "https://upload.wikimedia.org/wikipedia/commons/9/9f/Flag_of_the_Dominican_Republic.svg",
    iso2: "DO",
    iso3: "DOM",
  },
  {
    countryName: "Ecuador",
    flag: "https://upload.wikimedia.org/wikipedia/commons/e/e8/Flag_of_Ecuador.svg",
    iso2: "EC",
    iso3: "ECU",
  },
  {
    countryName: "Egypt",
    flag: "https://upload.wikimedia.org/wikipedia/commons/f/fe/Flag_of_Egypt.svg",
    iso2: "EG",
    iso3: "EGY",
  },
  {
    countryName: "El Salvador",
    flag: "https://upload.wikimedia.org/wikipedia/commons/3/34/Flag_of_El_Salvador.svg",
    iso2: "SV",
    iso3: "SLV",
  },
  {
    countryName: "Equatorial Guinea",
    flag: "https://upload.wikimedia.org/wikipedia/commons/3/31/Flag_of_Equatorial_Guinea.svg",
    iso2: "GQ",
    iso3: "GNQ",
  },
  {
    countryName: "Eritrea",
    flag: "https://upload.wikimedia.org/wikipedia/commons/2/29/Flag_of_Eritrea.svg",
    iso2: "ER",
    iso3: "ERI",
  },
  {
    countryName: "Estonia",
    flag: "https://upload.wikimedia.org/wikipedia/commons/8/8f/Flag_of_Estonia.svg",
    iso2: "EE",
    iso3: "EST",
  },
  {
    countryName: "Ethiopia",
    flag: "https://upload.wikimedia.org/wikipedia/commons/7/71/Flag_of_Ethiopia.svg",
    iso2: "ET",
    iso3: "ETH",
  },
  {
    countryName: "Falkland Islands",
    flag: "https://upload.wikimedia.org/wikipedia/commons/8/83/Flag_of_the_Falkland_Islands.svg",
    iso2: "FK",
    iso3: "FLK",
  },
  {
    countryName: "Faroe Islands",
    flag: "https://upload.wikimedia.org/wikipedia/commons/3/3c/Flag_of_the_Faroe_Islands.svg",
    iso2: "FO",
    iso3: "FRO",
  },
  {
    countryName: "Fiji",
    flag: "https://upload.wikimedia.org/wikipedia/commons/b/ba/Flag_of_Fiji.svg",
    iso2: "FJ",
    iso3: "FJI",
  },
  {
    countryName: "Finland",
    flag: "https://upload.wikimedia.org/wikipedia/commons/b/bc/Flag_of_Finland.svg",
    iso2: "FI",
    iso3: "FIN",
  },
  {
    countryName: "France",
    flag: "https://upload.wikimedia.org/wikipedia/en/c/c3/Flag_of_France.svg",
    iso2: "FR",
    iso3: "FRA",
  },
  {
    countryName: "French Polynesia",
    flag: "https://upload.wikimedia.org/wikipedia/commons/d/db/Flag_of_French_Polynesia.svg",
    iso2: "PF",
    iso3: "PYF",
  },
  {
    countryName: "Gabon",
    flag: "https://upload.wikimedia.org/wikipedia/commons/0/04/Flag_of_Gabon.svg",
    iso2: "GA",
    iso3: "GAB",
  },
  {
    countryName: "Gambia",
    flag: "https://upload.wikimedia.org/wikipedia/commons/7/77/Flag_of_The_Gambia.svg",
    iso2: "GM",
    iso3: "GMB",
  },
  {
    countryName: "Georgia",
    flag: "https://upload.wikimedia.org/wikipedia/commons/0/0f/Flag_of_Georgia.svg",
    iso2: "GE",
    iso3: "GEO",
  },
  {
    countryName: "Germany",
    flag: "https://upload.wikimedia.org/wikipedia/en/b/ba/Flag_of_Germany.svg",
    iso2: "DE",
    iso3: "DEU",
  },
  {
    countryName: "Ghana",
    flag: "https://upload.wikimedia.org/wikipedia/commons/1/19/Flag_of_Ghana.svg",
    iso2: "GH",
    iso3: "GHA",
  },
  {
    countryName: "Gibraltar",
    flag: "https://upload.wikimedia.org/wikipedia/commons/0/02/Flag_of_Gibraltar.svg",
    iso2: "GI",
    iso3: "GIB",
  },
  {
    countryName: "Greece",
    flag: "https://upload.wikimedia.org/wikipedia/commons/5/5c/Flag_of_Greece.svg",
    iso2: "GR",
    iso3: "GRC",
  },
  {
    countryName: "Greenland",
    flag: "https://upload.wikimedia.org/wikipedia/commons/0/09/Flag_of_Greenland.svg",
    iso2: "GL",
    iso3: "GRL",
  },
  {
    countryName: "Grenada",
    flag: "https://upload.wikimedia.org/wikipedia/commons/b/bc/Flag_of_Grenada.svg",
    iso2: "GD",
    iso3: "GRD",
  },
  {
    countryName: "Guadeloupe",
    flag: "https://upload.wikimedia.org/wikipedia/commons/7/7d/Flag_of_Guadeloupe_%28local%29_variant.svg",
    iso2: "GP",
    iso3: "GLP",
  },
  {
    countryName: "Guam",
    flag: "https://upload.wikimedia.org/wikipedia/commons/0/07/Flag_of_Guam.svg",
    iso2: "GU",
    iso3: "GUM",
  },
  {
    countryName: "Guatemala",
    flag: "https://upload.wikimedia.org/wikipedia/commons/e/ec/Flag_of_Guatemala.svg",
    iso2: "GT",
    iso3: "GTM",
  },
  {
    countryName: "Guernsey",
    flag: "https://upload.wikimedia.org/wikipedia/commons/f/fa/Flag_of_Guernsey.svg",
    iso2: "GG",
    iso3: "GGY",
  },
  {
    countryName: "Guinea",
    flag: "https://upload.wikimedia.org/wikipedia/commons/e/ed/Flag_of_Guinea.svg",
    iso2: "GN",
    iso3: "GIN",
  },
  {
    countryName: "Guinea-Bissau",
    flag: "https://upload.wikimedia.org/wikipedia/commons/0/01/Flag_of_Guinea-Bissau.svg",
    iso2: "GW",
    iso3: "GNB",
  },
  {
    countryName: "Guyana",
    flag: "https://upload.wikimedia.org/wikipedia/commons/9/99/Flag_of_Guyana.svg",
    iso2: "GY",
    iso3: "GUY",
  },
  {
    countryName: "Haiti",
    flag: "https://upload.wikimedia.org/wikipedia/commons/5/56/Flag_of_Haiti.svg",
    iso2: "HT",
    iso3: "HTI",
  },
  {
    countryName: "Heard Island and McDonald Islands",
    flag: "https://upload.wikimedia.org/wikipedia/commons/8/88/Flag_of_Australia_%28converted%29.svg",
    iso2: "HM",
    iso3: "HMD",
  },
  {
    countryName: "Vatican City State (Holy See)",
    flag: "https://upload.wikimedia.org/wikipedia/commons/0/00/Flag_of_the_Vatican_City.svg",
    iso2: "VA",
    iso3: "VAT",
  },
  {
    countryName: "Honduras",
    flag: "https://upload.wikimedia.org/wikipedia/commons/c/ca/Naval_Ensign_of_Honduras.svg",
    iso2: "HN",
    iso3: "HND",
  },
  {
    countryName: "Hong Kong",
    flag: "https://upload.wikimedia.org/wikipedia/commons/5/5b/Flag_of_Hong_Kong.svg",
    iso2: "HK",
    iso3: "HKG",
  },
  {
    countryName: "Hungary",
    flag: "https://upload.wikimedia.org/wikipedia/commons/c/c1/Flag_of_Hungary.svg",
    iso2: "HU",
    iso3: "HUN",
  },
  {
    countryName: "Iceland",
    flag: "https://upload.wikimedia.org/wikipedia/commons/c/ce/Flag_of_Iceland.svg",
    iso2: "IS",
    iso3: "ISL",
  },
  {
    countryName: "India",
    flag: "https://upload.wikimedia.org/wikipedia/en/4/41/Flag_of_India.svg",
    iso2: "IN",
    iso3: "IND",
  },
  {
    countryName: "Indonesia",
    flag: "https://upload.wikimedia.org/wikipedia/commons/9/9f/Flag_of_Indonesia.svg",
    iso2: "ID",
    iso3: "IDN",
  },
  {
    countryName: "Iran",
    flag: " https://upload.wikimedia.org/wikipedia/commons/c/ca/Flag_of_Iran.svg",
    iso2: "IR",
    iso3: "IRN",
  },
  {
    countryName: "Iraq",
    flag: "https://upload.wikimedia.org/wikipedia/commons/f/f6/Flag_of_Iraq.svg",
    iso2: "IQ",
    iso3: "IRQ",
  },
  {
    countryName: "Ireland",
    flag: "https://upload.wikimedia.org/wikipedia/commons/4/45/Flag_of_Ireland.svg",
    iso2: "IE",
    iso3: "IRL",
  },
  {
    countryName: "Isle of Man",
    flag: "https://upload.wikimedia.org/wikipedia/commons/b/bc/Flag_of_the_Isle_of_Man.svg",
    iso2: "IM",
    iso3: "IMN",
  },
  {
    countryName: "Israel",
    flag: "https://upload.wikimedia.org/wikipedia/commons/d/d4/Flag_of_Israel.svg",
    iso2: "IL",
    iso3: "ISR",
  },
  {
    countryName: "Italy",
    flag: "https://upload.wikimedia.org/wikipedia/en/0/03/Flag_of_Italy.svg",
    iso2: "IT",
    iso3: "ITA",
  },
  {
    countryName: "Jamaica",
    flag: "https://upload.wikimedia.org/wikipedia/commons/0/0a/Flag_of_Jamaica.svg",
    iso2: "JM",
    iso3: "JAM",
  },
  {
    countryName: "Japan",
    flag: "https://upload.wikimedia.org/wikipedia/en/9/9e/Flag_of_Japan.svg",
    iso2: "JP",
    iso3: "JPN",
  },
  {
    countryName: "Jersey",
    flag: "https://upload.wikimedia.org/wikipedia/commons/1/1c/Flag_of_Jersey.svg",
    iso2: "JE",
    iso3: "JEY",
  },
  {
    countryName: "Jordan",
    flag: "https://upload.wikimedia.org/wikipedia/commons/c/c0/Flag_of_Jordan.svg",
    iso2: "JO",
    iso3: "JOR",
  },
  {
    countryName: "Kazakhstan",
    flag: "https://upload.wikimedia.org/wikipedia/commons/d/d3/Flag_of_Kazakhstan.svg",
    iso2: "KZ",
    iso3: "KAZ",
  },
  {
    countryName: "Kenya",
    flag: "https://upload.wikimedia.org/wikipedia/commons/4/49/Flag_of_Kenya.svg",
    iso2: "KE",
    iso3: "KEN",
  },
  {
    countryName: "Kiribati",
    flag: "https://upload.wikimedia.org/wikipedia/commons/d/d3/Flag_of_Kiribati.svg",
    iso2: "KI",
    iso3: "KIR",
  },
  {
    countryName: "Kuwait",
    flag: "https://upload.wikimedia.org/wikipedia/commons/a/aa/Flag_of_Kuwait.svg",
    iso2: "KW",
    iso3: "KWT",
  },
  {
    countryName: "Kyrgyzstan",
    flag: "https://upload.wikimedia.org/wikipedia/commons/c/c7/Flag_of_Kyrgyzstan.svg",
    iso2: "KG",
    iso3: "KGZ",
  },
  {
    countryName: "Laos",
    flag: "https://upload.wikimedia.org/wikipedia/commons/5/56/Flag_of_Laos.svg",
    iso2: "LA",
    iso3: "LAO",
  },
  {
    countryName: "Latvia",
    flag: "https://upload.wikimedia.org/wikipedia/commons/8/84/Flag_of_Latvia.svg",
    iso2: "LV",
    iso3: "LVA",
  },
  {
    countryName: "Lebanon",
    flag: "https://upload.wikimedia.org/wikipedia/commons/5/59/Flag_of_Lebanon.svg",
    iso2: "LB",
    iso3: "LBN",
  },
  {
    countryName: "Lesotho",
    flag: "https://upload.wikimedia.org/wikipedia/commons/4/4a/Flag_of_Lesotho.svg",
    iso2: "LS",
    iso3: "LSO",
  },
  {
    countryName: "Liberia",
    flag: "https://upload.wikimedia.org/wikipedia/commons/b/b8/Flag_of_Liberia.svg",
    iso2: "LR",
    iso3: "LBR",
  },
  {
    countryName: "Liechtenstein",
    flag: "https://upload.wikimedia.org/wikipedia/commons/4/47/Flag_of_Liechtenstein.svg",
    iso2: "LI",
    iso3: "LIE",
  },
  {
    countryName: "Lithuania",
    flag: "https://upload.wikimedia.org/wikipedia/commons/1/11/Flag_of_Lithuania.svg",
    iso2: "LT",
    iso3: "LTU",
  },
  {
    countryName: "Luxembourg",
    flag: "https://upload.wikimedia.org/wikipedia/commons/d/da/Flag_of_Luxembourg.svg",
    iso2: "LU",
    iso3: "LUX",
  },
  {
    countryName: "Macau",
    flag: "https://upload.wikimedia.org/wikipedia/commons/6/63/Flag_of_Macau.svg",
    iso2: "MO",
    iso3: "MAC",
  },
  {
    countryName: "Madagascar",
    flag: "https://upload.wikimedia.org/wikipedia/commons/b/bc/Flag_of_Madagascar.svg",
    iso2: "MG",
    iso3: "MDG",
  },
  {
    countryName: "Malawi",
    flag: "https://upload.wikimedia.org/wikipedia/commons/d/d1/Flag_of_Malawi.svg",
    iso2: "MW",
    iso3: "MWI",
  },
  {
    countryName: "Malaysia",
    flag: "https://upload.wikimedia.org/wikipedia/commons/6/66/Flag_of_Malaysia.svg",
    iso2: "MY",
    iso3: "MYS",
  },
  {
    countryName: "Maldives",
    flag: "https://upload.wikimedia.org/wikipedia/commons/0/0f/Flag_of_Maldives.svg",
    iso2: "MV",
    iso3: "MDV",
  },
  {
    countryName: "Mali",
    flag: "https://upload.wikimedia.org/wikipedia/commons/9/92/Flag_of_Mali.svg",
    iso2: "ML",
    iso3: "MLI",
  },
  {
    countryName: "Malta",
    flag: "https://upload.wikimedia.org/wikipedia/commons/7/73/Flag_of_Malta.svg",
    iso2: "MT",
    iso3: "MLT",
  },
  {
    countryName: "Marshall Islands",
    flag: "https://upload.wikimedia.org/wikipedia/commons/2/2e/Flag_of_the_Marshall_Islands.svg",
    iso2: "MH",
    iso3: "MHL",
  },
  {
    countryName: "Martinique",
    flag: "https://upload.wikimedia.org/wikipedia/commons/6/64/Snake_Flag_of_Martinique.svg",
    iso2: "MQ",
    iso3: "MTQ",
  },
  {
    countryName: "Mauritania",
    flag: "https://upload.wikimedia.org/wikipedia/commons/4/43/Flag_of_Mauritania.svg",
    iso2: "MR",
    iso3: "MRT",
  },
  {
    countryName: "Mauritius",
    flag: "https://upload.wikimedia.org/wikipedia/commons/7/77/Flag_of_Mauritius.svg",
    iso2: "MU",
    iso3: "MUS",
  },
  {
    countryName: "Mayotte",
    flag: "https://upload.wikimedia.org/wikipedia/en/c/c3/Flag_of_France.svg",
    iso2: "YT",
    iso3: "MYT",
  },
  {
    countryName: "Mexico",
    flag: "https://upload.wikimedia.org/wikipedia/commons/f/fc/Flag_of_Mexico.svg",
    iso2: "MX",
    iso3: "MEX",
  },
  {
    countryName: "Monaco",
    flag: "https://upload.wikimedia.org/wikipedia/commons/e/ea/Flag_of_Monaco.svg",
    iso2: "MC",
    iso3: "MCO",
  },
  {
    countryName: "Mongolia",
    flag: "https://upload.wikimedia.org/wikipedia/commons/4/4c/Flag_of_Mongolia.svg",
    iso2: "MN",
    iso3: "MNG",
  },
  {
    countryName: "Montenegro",
    flag: "https://upload.wikimedia.org/wikipedia/commons/6/64/Flag_of_Montenegro.svg",
    iso2: "ME",
    iso3: "MNE",
  },
  {
    countryName: "Montserrat",
    flag: "https://upload.wikimedia.org/wikipedia/commons/d/d0/Flag_of_Montserrat.svg",
    iso2: "MS",
    iso3: "MSR",
  },
  {
    countryName: "Morocco",
    flag: "https://upload.wikimedia.org/wikipedia/commons/2/2c/Flag_of_Morocco.svg",
    iso2: "MA",
    iso3: "MAR",
  },
  {
    countryName: "Mozambique",
    flag: "https://upload.wikimedia.org/wikipedia/commons/d/d0/Flag_of_Mozambique.svg",
    iso2: "MZ",
    iso3: "MOZ",
  },
  {
    countryName: "Myanmar",
    flag: "https://upload.wikimedia.org/wikipedia/commons/8/8c/Flag_of_Myanmar.svg",
    iso2: "MM",
    iso3: "MMR",
  },
  {
    countryName: "Namibia",
    flag: "https://upload.wikimedia.org/wikipedia/commons/0/00/Flag_of_Namibia.svg",
    iso2: "NA",
    iso3: "NAM",
  },
  {
    countryName: "Nauru",
    flag: "https://upload.wikimedia.org/wikipedia/commons/3/30/Flag_of_Nauru.svg",
    iso2: "NR",
    iso3: "NRU",
  },
  {
    countryName: "Nepal",
    flag: "https://upload.wikimedia.org/wikipedia/commons/9/9b/Flag_of_Nepal.svg",
    iso2: "NP",
    iso3: "NPL",
  },
  {
    countryName: "Netherlands",
    flag: "https://upload.wikimedia.org/wikipedia/commons/2/20/Flag_of_the_Netherlands.svg",
    iso2: "NL",
    iso3: "NLD",
  },
  {
    countryName: "New Caledonia",
    flag: "https://upload.wikimedia.org/wikipedia/commons/2/26/Flags_of_New_Caledonia.svg",
    iso2: "NC",
    iso3: "NCL",
  },
  {
    countryName: "New Zealand",
    flag: "https://upload.wikimedia.org/wikipedia/commons/3/3e/Flag_of_New_Zealand.svg",
    iso2: "NZ",
    iso3: "NZL",
  },
  {
    countryName: "Nicaragua",
    flag: "https://upload.wikimedia.org/wikipedia/commons/1/19/Flag_of_Nicaragua.svg",
    iso2: "NI",
    iso3: "NIC",
  },
  {
    countryName: "Niger",
    flag: "https://upload.wikimedia.org/wikipedia/commons/f/f4/Flag_of_Niger.svg",
    iso2: "NE",
    iso3: "NER",
  },
  {
    countryName: "Nigeria",
    flag: "https://upload.wikimedia.org/wikipedia/commons/7/79/Flag_of_Nigeria.svg",
    iso2: "NG",
    iso3: "NGA",
  },
  {
    countryName: "Niue",
    flag: "https://upload.wikimedia.org/wikipedia/commons/0/01/Flag_of_Niue.svg",
    iso2: "NU",
    iso3: "NIU",
  },
  {
    countryName: "Norfolk Island",
    flag: "https://upload.wikimedia.org/wikipedia/commons/4/48/Flag_of_Norfolk_Island.svg",
    iso2: "NF",
    iso3: "NFK",
  },
  {
    countryName: "Northern Mariana Islands",
    flag: "https://upload.wikimedia.org/wikipedia/commons/e/e0/Flag_of_the_Northern_Mariana_Islands.svg",
    iso2: "MP",
    iso3: "MNP",
  },
  {
    countryName: "Norway",
    flag: "https://upload.wikimedia.org/wikipedia/commons/d/d9/Flag_of_Norway.svg",
    iso2: "NO",
    iso3: "NOR",
  },
  {
    countryName: "Oman",
    flag: "https://upload.wikimedia.org/wikipedia/commons/d/dd/Flag_of_Oman.svg",
    iso2: "OM",
    iso3: "OMN",
  },
  {
    countryName: "Pakistan",
    flag: "https://upload.wikimedia.org/wikipedia/commons/3/32/Flag_of_Pakistan.svg",
    iso2: "PK",
    iso3: "PAK",
  },
  {
    countryName: "Palau",
    flag: "https://upload.wikimedia.org/wikipedia/commons/4/48/Flag_of_Palau.svg",
    iso2: "PW",
    iso3: "PLW",
  },
  {
    countryName: "Panama",
    flag: "https://upload.wikimedia.org/wikipedia/commons/a/ab/Flag_of_Panama.svg",
    iso2: "PA",
    iso3: "PAN",
  },
  {
    countryName: "Papua New Guinea",
    flag: "https://upload.wikimedia.org/wikipedia/commons/e/e3/Flag_of_Papua_New_Guinea.svg",
    iso2: "PG",
    iso3: "PNG",
  },
  {
    countryName: "Paraguay",
    flag: "https://upload.wikimedia.org/wikipedia/commons/2/27/Flag_of_Paraguay.svg",
    iso2: "PY",
    iso3: "PRY",
  },
  {
    countryName: "Peru",
    flag: "https://upload.wikimedia.org/wikipedia/commons/c/cf/Flag_of_Peru.svg",
    iso2: "PE",
    iso3: "PER",
  },
  {
    countryName: "Philippines",
    flag: "https://upload.wikimedia.org/wikipedia/commons/9/99/Flag_of_the_Philippines.svg",
    iso2: "PH",
    iso3: "PHL",
  },
  {
    countryName: "Pitcairn",
    flag: "https://upload.wikimedia.org/wikipedia/commons/8/88/Flag_of_the_Pitcairn_Islands.svg",
    iso2: "PN",
    iso3: "PCN",
  },
  {
    countryName: "Poland",
    flag: "https://upload.wikimedia.org/wikipedia/en/1/12/Flag_of_Poland.svg",
    iso2: "PL",
    iso3: "POL",
  },
  {
    countryName: "Portugal",
    flag: "https://upload.wikimedia.org/wikipedia/commons/5/5c/Flag_of_Portugal.svg",
    iso2: "PT",
    iso3: "PRT",
  },
  {
    countryName: "Puerto Rico",
    flag: "https://upload.wikimedia.org/wikipedia/commons/2/28/Flag_of_Puerto_Rico.svg",
    iso2: "PR",
    iso3: "PRI",
  },
  {
    countryName: "Qatar",
    flag: "https://upload.wikimedia.org/wikipedia/commons/6/65/Flag_of_Qatar.svg",
    iso2: "QA",
    iso3: "QAT",
  },
  {
    countryName: "Réunion",
    flag: "https://upload.wikimedia.org/wikipedia/en/c/c3/Flag_of_France.svg",
    iso2: "RE",
    iso3: "REU",
  },
  {
    countryName: "Romania",
    flag: "https://upload.wikimedia.org/wikipedia/commons/7/73/Flag_of_Romania.svg",
    iso2: "RO",
    iso3: "ROU",
  },
  {
    countryName: "Rwanda",
    flag: "https://upload.wikimedia.org/wikipedia/commons/1/17/Flag_of_Rwanda.svg",
    iso2: "RW",
    iso3: "RWA",
  },
  {
    countryName: "Saint Kitts and Nevis",
    flag: "https://upload.wikimedia.org/wikipedia/commons/f/fe/Flag_of_Saint_Kitts_and_Nevis.svg",
    iso2: "KN",
    iso3: "KNA",
  },
  {
    countryName: "Saint Lucia",
    flag: "https://upload.wikimedia.org/wikipedia/commons/9/9f/Flag_of_Saint_Lucia.svg",
    iso2: "LC",
    iso3: "LCA",
  },
  {
    countryName: "Saint Pierre and Miquelon",
    flag: "https://upload.wikimedia.org/wikipedia/commons/7/74/Flag_of_Saint-Pierre_and_Miquelon.svg",
    iso2: "PM",
    iso3: "SPM",
  },
  {
    countryName: "Saint Vincent and the Grenadines",
    flag: "https://upload.wikimedia.org/wikipedia/commons/6/6d/Flag_of_Saint_Vincent_and_the_Grenadines.svg",
    iso2: "VC",
    iso3: "VCT",
  },
  {
    countryName: "Samoa",
    flag: "https://upload.wikimedia.org/wikipedia/commons/3/31/Flag_of_Samoa.svg",
    iso2: "WS",
    iso3: "WSM",
  },
  {
    countryName: "San Marino",
    flag: "https://upload.wikimedia.org/wikipedia/commons/b/b1/Flag_of_San_Marino.svg",
    iso2: "SM",
    iso3: "SMR",
  },
  {
    countryName: "Sao Tome and Principe",
    flag: "https://upload.wikimedia.org/wikipedia/commons/4/4f/Flag_of_Sao_Tome_and_Principe.svg",
    iso2: "ST",
    iso3: "STP",
  },
  {
    countryName: "Saudi Arabia",
    flag: "https://upload.wikimedia.org/wikipedia/commons/0/0d/Flag_of_Saudi_Arabia.svg",
    iso2: "SA",
    iso3: "SAU",
  },
  {
    countryName: "Senegal",
    flag: "https://upload.wikimedia.org/wikipedia/commons/f/fd/Flag_of_Senegal.svg",
    iso2: "SN",
    iso3: "SEN",
  },
  {
    countryName: "Serbia",
    flag: "https://upload.wikimedia.org/wikipedia/commons/f/ff/Flag_of_Serbia.svg",
    iso2: "RS",
    iso3: "SRB",
  },
  {
    countryName: "Seychelles",
    flag: "https://upload.wikimedia.org/wikipedia/commons/f/fc/Flag_of_Seychelles.svg",
    iso2: "SC",
    iso3: "SYC",
  },
  {
    countryName: "Sierra Leone",
    flag: "https://upload.wikimedia.org/wikipedia/commons/1/17/Flag_of_Sierra_Leone.svg",
    iso2: "SL",
    iso3: "SLE",
  },
  {
    countryName: "Singapore",
    flag: "https://upload.wikimedia.org/wikipedia/commons/4/48/Flag_of_Singapore.svg",
    iso2: "SG",
    iso3: "SGP",
  },
  {
    countryName: "Slovakia",
    flag: "https://upload.wikimedia.org/wikipedia/commons/e/e6/Flag_of_Slovakia.svg",
    iso2: "SK",
    iso3: "SVK",
  },
  {
    countryName: "Slovenia",
    flag: "https://upload.wikimedia.org/wikipedia/commons/f/f0/Flag_of_Slovenia.svg",
    iso2: "SI",
    iso3: "SVN",
  },
  {
    countryName: "Solomon Islands",
    flag: "https://upload.wikimedia.org/wikipedia/commons/7/74/Flag_of_the_Solomon_Islands.svg",
    iso2: "SB",
    iso3: "SLB",
  },
  {
    countryName: "Somalia",
    flag: "https://upload.wikimedia.org/wikipedia/commons/a/a0/Flag_of_Somalia.svg",
    iso2: "SO",
    iso3: "SOM",
  },
  {
    countryName: "South Africa",
    flag: "https://upload.wikimedia.org/wikipedia/commons/a/af/Flag_of_South_Africa.svg",
    iso2: "ZA",
    iso3: "ZAF",
  },
  {
    countryName: "South Georgia and the South Sandwich Islands",
    flag: "https://upload.wikimedia.org/wikipedia/commons/e/ed/Flag_of_South_Georgia_and_the_South_Sandwich_Islands.svg",
    iso2: "GS",
    iso3: "SGS",
  },
  {
    countryName: "Spain",
    flag: "https://upload.wikimedia.org/wikipedia/en/9/9a/Flag_of_Spain.svg",
    iso2: "ES",
    iso3: "ESP",
  },
  {
    countryName: "Sri Lanka",
    flag: "https://upload.wikimedia.org/wikipedia/commons/1/11/Flag_of_Sri_Lanka.svg",
    iso2: "LK",
    iso3: "LKA",
  },
  {
    countryName: "Sudan",
    flag: "https://upload.wikimedia.org/wikipedia/commons/0/01/Flag_of_Sudan.svg",
    iso2: "SD",
    iso3: "SDN",
  },
  {
    countryName: "Suriname",
    flag: "https://upload.wikimedia.org/wikipedia/commons/6/60/Flag_of_Suriname.svg",
    iso2: "SR",
    iso3: "SUR",
  },
  {
    countryName: "Swaziland",
    flag: "https://upload.wikimedia.org/wikipedia/commons/f/fb/Flag_of_Eswatini.svg",
    iso2: "SZ",
    iso3: "SWZ",
  },
  {
    countryName: "Sweden",
    flag: "https://upload.wikimedia.org/wikipedia/en/4/4c/Flag_of_Sweden.svg",
    iso2: "SE",
    iso3: "SWE",
  },
  {
    countryName: "Switzerland",
    flag: "https://upload.wikimedia.org/wikipedia/commons/0/08/Flag_of_Switzerland_%28Pantone%29.svg",
    iso2: "CH",
    iso3: "CHE",
  },
  {
    countryName: "Syria",
    flag: "https://upload.wikimedia.org/wikipedia/commons/5/53/Flag_of_Syria.svg",
    iso2: "SY",
    iso3: "SYR",
  },
  {
    countryName: "Taiwan",
    flag: "https://upload.wikimedia.org/wikipedia/commons/7/72/Flag_of_the_Republic_of_China.svg",
    iso2: "TW",
    iso3: "TWN",
  },
  {
    countryName: "Tajikistan",
    flag: "https://upload.wikimedia.org/wikipedia/commons/d/d0/Flag_of_Tajikistan.svg",
    iso2: "TJ",
    iso3: "TJK",
  },
  {
    countryName: "Thailand",
    flag: "https://upload.wikimedia.org/wikipedia/commons/a/a9/Flag_of_Thailand.svg",
    iso2: "TH",
    iso3: "THA",
  },
  {
    countryName: "Timor-Leste",
    flag: "https://upload.wikimedia.org/wikipedia/commons/2/26/Flag_of_East_Timor.svg",
    iso2: "TL",
    iso3: "TLS",
  },
  {
    countryName: "Togo",
    flag: "https://upload.wikimedia.org/wikipedia/commons/6/68/Flag_of_Togo.svg",
    iso2: "TG",
    iso3: "TGO",
  },
  {
    countryName: "Tokelau",
    flag: "https://upload.wikimedia.org/wikipedia/commons/8/8e/Flag_of_Tokelau.svg",
    iso2: "TK",
    iso3: "TKL",
  },
  {
    countryName: "Tonga",
    flag: "https://upload.wikimedia.org/wikipedia/commons/9/9a/Flag_of_Tonga.svg",
    iso2: "TO",
    iso3: "TON",
  },
  {
    countryName: "Trinidad and Tobago",
    flag: "https://upload.wikimedia.org/wikipedia/commons/6/64/Flag_of_Trinidad_and_Tobago.svg",
    iso2: "TT",
    iso3: "TTO",
  },
  {
    countryName: "Tunisia",
    flag: "https://upload.wikimedia.org/wikipedia/commons/c/ce/Flag_of_Tunisia.svg",
    iso2: "TN",
    iso3: "TUN",
  },
  {
    countryName: "Turkey",
    flag: "https://upload.wikimedia.org/wikipedia/commons/b/b4/Flag_of_Turkey.svg",
    iso2: "TR",
    iso3: "TUR",
  },
  {
    countryName: "Turkmenistan",
    flag: "https://upload.wikimedia.org/wikipedia/commons/1/1b/Flag_of_Turkmenistan.svg",
    iso2: "TM",
    iso3: "TKM",
  },
  {
    countryName: "Turks and Caicos Islands",
    flag: "https://upload.wikimedia.org/wikipedia/commons/a/a0/Flag_of_the_Turks_and_Caicos_Islands.svg",
    iso2: "TC",
    iso3: "TCA",
  },
  {
    countryName: "Tuvalu",
    flag: "https://upload.wikimedia.org/wikipedia/commons/3/38/Flag_of_Tuvalu.svg",
    iso2: "TV",
    iso3: "TUV",
  },
  {
    countryName: "Uganda",
    flag: "https://upload.wikimedia.org/wikipedia/commons/4/4e/Flag_of_Uganda.svg",
    iso2: "UG",
    iso3: "UGA",
  },
  {
    countryName: "Ukraine",
    flag: "https://upload.wikimedia.org/wikipedia/commons/4/49/Flag_of_Ukraine.svg",
    iso2: "UA",
    iso3: "UKR",
  },
  {
    countryName: "United Arab Emirates",
    flag: "https://upload.wikimedia.org/wikipedia/commons/c/cb/Flag_of_the_United_Arab_Emirates.svg",
    iso2: "AE",
    iso3: "ARE",
  },
  {
    countryName: "United Kingdom",
    flag: "https://upload.wikimedia.org/wikipedia/en/a/ae/Flag_of_the_United_Kingdom.svg",
    iso2: "GB",
    iso3: "GBR",
  },
  {
    countryName: "United States",
    flag: "https://upload.wikimedia.org/wikipedia/en/a/a4/Flag_of_the_United_States.svg",
    iso2: "US",
    iso3: "USA",
  },
  {
    countryName: "United States Minor Outlying Islands",
    flag: "https://upload.wikimedia.org/wikipedia/en/a/a4/Flag_of_the_United_States.svg",
    iso2: "UM",
    iso3: "UMI",
  },
  {
    countryName: "Uruguay",
    flag: "https://upload.wikimedia.org/wikipedia/commons/f/fe/Flag_of_Uruguay.svg",
    iso2: "UY",
    iso3: "URY",
  },
  {
    countryName: "Uzbekistan",
    flag: "https://upload.wikimedia.org/wikipedia/commons/8/84/Flag_of_Uzbekistan.svg",
    iso2: "UZ",
    iso3: "UZB",
  },
  {
    countryName: "Vanuatu",
    flag: "https://upload.wikimedia.org/wikipedia/commons/b/bc/Flag_of_Vanuatu.svg",
    iso2: "VU",
    iso3: "VUT",
  },
  {
    countryName: "Vietnam",
    flag: "https://upload.wikimedia.org/wikipedia/commons/2/21/Flag_of_Vietnam.svg",
    iso2: "VN",
    iso3: "VNM",
  },
  {
    countryName: "Wallis and Futuna",
    flag: "https://upload.wikimedia.org/wikipedia/commons/d/d2/Flag_of_Wallis_and_Futuna.svg",
    iso2: "WF",
    iso3: "WLF",
  },
  {
    countryName: "Yemen",
    flag: "https://upload.wikimedia.org/wikipedia/commons/8/89/Flag_of_Yemen.svg",
    iso2: "YE",
    iso3: "YEM",
  },
  {
    countryName: "Zambia",
    flag: "https://upload.wikimedia.org/wikipedia/commons/0/06/Flag_of_Zambia.svg",
    iso2: "ZM",
    iso3: "ZMB",
  },
  {
    countryName: "Zimbabwe",
    flag: "https://upload.wikimedia.org/wikipedia/commons/6/6a/Flag_of_Zimbabwe.svg",
    iso2: "ZW",
    iso3: "ZWE",
  },
];
export const languageList = [
  {
    id: 1,
    languageName: "English (US)",
  },
  {
    id: 2,
    languageName: "Mandrin",
  },
  {
    id: 3,
    languageName: "Japanees",
  },
  {
    id: 4,
    languageName: "Hindi",
  },
  {
    id: 5,
    languageName: "Pashtu",
  },
];
export const genderList = [
  {
    id: 1,
    genderName: "Male",
  },
  {
    id: 2,
    genderName: "Female",
  },
  {
    id: 3,
    genderName: "Other",
  },
];

export const share = [
  {
    id: 1,
    src: "assets/photos/CommentPic.png",
    name: "Robert Fin",
    userName: "Robert Fin",
  },
  {
    id: 2,
    src: "assets/photos/CommentPic.png",
    name: "Robert Fin",
    userName: "Robert Fin",
  },
  {
    id: 3,
    src: "assets/photos/CommentPic.png",
    name: "Robert Fin",
    userName: "Robert Fin",
  },
];
